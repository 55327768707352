<template>
  <v-dialog :value="value" persistent width="1000">
    <v-card class="fill" height="800">
      <v-card-title>
        {{selectedAd.ad_name}} Notes
        <v-spacer/>
        <v-btn icon  @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-container class="ma-0 pa-6">
        <v-row dense justify="center" class="pa-4">
          <v-col md="11" lg="11">
            <v-textarea flat hide-details outlined no-resize rows="3" label="Leave a note..."
              background-color="input" @keydown.enter="saveAdNote" v-model="note.note_text">
              <template v-slot:append>
                <v-btn class="mx-0" depressed @click="saveAdNote">
                  Post
                </v-btn>
              </template>
            </v-textarea>
          </v-col>
        </v-row>
        <v-container class="pa-4"  style="border:1px solid grey; border-radius:5px; height:500px;overflow-y:auto">
          <v-card v-for="(note, index) in notes" :key="index" class="ma-2 pa-4">
            <v-card-title class="text-h6 text-capitalize">
              {{note.audit.created_by}}
            </v-card-title>
            <v-card-text class="pa-6">
              <p class="black--text">{{note.note_text}}</p>
            </v-card-text>
            <v-card-actions class="card">
              <v-spacer />
              <small>{{moment(note.audit.created_on).format($dateConfig.timestamp)}}</small>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-container>
    </v-card>
  </v-dialog>
</template>


<script>
import { notification } from '@/mixins/notification'


export default {
  name: 'AdNotes',

  mixins: [notification],

  props: ['value', 'selectedAd'],

  data () {
    return {
      note: {},
      notes:[],
      selected: []
    }
  },
  
  watch: {
    value: {
      immediate: true,
      handler () {
        if (this.value) this.getAdNotes()
      }
    }
  },

  methods: {
    async saveAdNote () {
      this.note.ad_id = this.selectedAd.id
      await this.$Ads.saveAdNote(this.note)
        .then(() => {
          this.note.note_text = ''
          this.$store.dispatch('setSnackbar', { status: 'success', text: 'Saved Note Successfully' })
        }).catch(err => {
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Save Note due to ${err?.response?.data?.message || err.message}` })
        })
      this.getAdNotes()
    },

    async getAdNotes () {
      await this.$Ads.getAdNotes(this.selectedAd.id)
        .then(res => {
          this.notes = res.data
          if (this.selectedAd && this.selectedAd.notes) {
            const adNote = {
              ad_id: this.selectedAd.ad_id,
              audit: {
                created_by: this.selectedAd.audit.created_by,
                created_on: this.selectedAd.audit.created_on
              },
              note_text: this.selectedAd.notes
            }
            this.notes.push(adNote)
          }
          this.notes = this._.orderBy(res.data, ['audit.created_on'], ['desc'])
        }).catch(err=>{
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Get Notes due to ${err?.response?.data?.message || err.message}` })
        })
    }
  }
}
</script>
